import React, { useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// API
import { getUserProfile } from "../ducks/actions";

// Scoped CSS
import styles from "./Home.module.scss";

// Components
import ImgTxtTwoCol from "../components/common/ImgTxtTwoCol";
import Banner from "../components/Banner";
import ProductIngredientBox from "../components/Home/ProductIngredientBox";

// Images
import basketballImg from "../assets/images/fitness-sport-01.jpg";

import sachetImg from "../assets/images/home/Monolith Nutrition-MCT Essentials-Lemon Blast-Sachet-Packs.png";
import skewGutHealthImg from "../assets/images/home/skew/gutHealth.jpg";
import skewWeightMgmtImg from "../assets/images/home/skew/weightMgmt.jpg";
import skewCogHealthImg from "../assets/images/home/skew/cognitiveHealth.png";
import skewCoconutImg from "../assets/images/home/skew/mct_coconut_image.png";

import gridGutImg from "../assets/images/home/grid/gridGutHealth.png";
import gridBrainImg from "../assets/images/home/grid/brain.jpg";
import gridMixImg from "../assets/images/home/grid/mix.jpg";
import gridTasteImg from "../assets/images/home/grid/taste.png";
import gridKetonesImg from "../assets/images/home/grid/ketones.jpg";
import gridSachetImg from "../assets/images/home/grid/sachet.png";

import freeSampleImg from "../assets/images/free-sample.png";

// Antd
import { Modal } from "antd";
import { Link } from "react-router-dom";

const productIngredients = [
  {
    image: gridGutImg,
    // desc: (
    //   <div>
    //     <span className="text-red">100% COCONUT C8 and C10 MCT </span>derived
    //     from sustainable sources.
    //   </div>
    // ),
    desc: (
      <div>
        One of the key tenets of foundational nutrition is{" "}
        <span className="text-red">gut health</span> and how it relates
        synergistically with food consumption and phytonutrients for health and
        wellness – a champion cause for Monolith Nutrition’s MCT Essentials
        Series of nutritional supplements.
      </div>
    ),
    title: "Towards optimum gut health",
    alt: "100% COCONUT C8 and C10 MCT derived from sustainable sources.",
    imgSize: "cover",
  },
  {
    image: gridKetonesImg,
    // desc: (
    //   <div>
    //     <span className="text-red">POST</span>BIOTICS proprietary matrix
    //     <sup>
    //       <small>2</small>
    //     </sup>{" "}
    //     prospers gut microbiome for better digestion. Happy and healthy gut!{" "}
    //   </div>
    // ),
    desc: (
      <div>
        Stimulate natural <span className="text-red">metabolism</span>, induce{" "}
        <span className="text-red">thermogenesis</span>, and mild states of{" "}
        <span className="text-red">ketosis</span> – all made possible by the
        rapid absorption and beta oxidation of MCT’s shorter chain length and
        bioactivity as a functional food ingredient.
      </div>
    ),
    title: "Smart and sensible weight management",
    alt: "POSTBIOTICS proprietary matrix2 prospers gut microbiome for better digestion. Happy and healthy gut!",
  },
  {
    image: gridBrainImg,
    // desc: (
    //   <div>
    //     Not just ordinary Vitamin E - the benefits of{" "}
    //     <span className="text-red">TOCOTRIENOL</span> for better immune health
    //   </div>
    // ),
    desc: (
      <div>
        A <span className="text-red">healthy brain</span> relies on a{" "}
        <span className="text-red">healthy gut</span> (surprise, surprise!). The
        benefits of foundational nutrition however takes things further as
        Monolith Nutrition’s MCT Series of nutritional mixes works to aid the
        conditioning of your body to naturally use ketones as a fuel source
        rather than plain old glucose for a boost in cognitive health.
      </div>
    ),
    title: "Give the brain a boost",
    alt: "Not just ordinary Vitamin E – the benefits of TOCOTRIENOL for better immune health",
    imgSize: "cover",
  },
  {
    image: gridTasteImg,
    // desc: (
    //   <div>
    //     Formulated for maximum ease of{" "}
    //     <span className="text-red">MIXABILITY</span> with an all-natural{" "}
    //     <span className="text-red">TERRIFIC TASTE</span> profile.
    //   </div>
    // ),
    desc: (
      <div>
        Formulated by food ingredient experts to{" "}
        <span className="text-red">please taste buds</span> all around, you will
        be literally craving the next sachet serving! Who says good health can’t
        go with great taste?
      </div>
    ),
    title: "Flavour & Taste: We got it oh so right",
    alt: "Formulated for maximum ease of MIXABILITY with an all-natural TERRIFIC TASTE profile.",
  },
  {
    image: gridSachetImg,
    // desc: (
    //   <div>
    //     Formulated for maximum ease of{" "}
    //     <span className="text-red">MIXABILITY</span> with an all-natural{" "}
    //     <span className="text-red">TERRIFIC TASTE</span> profile.
    //   </div>
    // ),
    desc: (
      <div>
        Monolith Nutrition’s MCT Essentials Series of products understands the
        need for <span className="text-red">value</span>,{" "}
        <span className="text-red">convenience</span> and{" "}
        <span className="text-red">affordability</span>. Every sachet is packed
        with value goodness that does not break your bank, allowing you to
        incorporate MCT into your health regimen sustainably and sensibly.
      </div>
    ),
    title: "Affordable, chic, and convenient lifestyle sachets",
    alt: "Formulated for maximum ease of MIXABILITY with an all-natural TERRIFIC TASTE profile.",
  },
  {
    image: gridMixImg,
    // desc: (
    //   <div>
    //     Formulated for maximum ease of{" "}
    //     <span className="text-red">MIXABILITY</span> with an all-natural{" "}
    //     <span className="text-red">TERRIFIC TASTE</span> profile.
    //   </div>
    // ),
    desc: (
      <div>
        MCT powder is notoriously difficult to mix right without added fillers.
        Enjoy 100% organic coconut MCT with{" "}
        <span className="text-red">amazing</span> mixability whether you like
        using cold or hot water. Zero fillers, zero frills, zero hassle even
        when mixed on the go.
      </div>
    ),
    title: "Easy-mix formulation",
    alt: "Formulated for maximum ease of MIXABILITY with an all-natural TERRIFIC TASTE profile.",
  },
];

const mctBenefits = {
  gutHealth: {
    title: "PUTTING PRIORITY IN YOUR GUT HEALTH",
    body: "One of the key tenets of foundational nutrition is gut health and how it relates synergistically with food consumption and phytonutrients for health and wellness – a champion cause for Monolith Nutrition’s MCT Essentials Series of nutritional supplements.",
    image: skewGutHealthImg,
    imgDirection: "right",
    imgSpaceDirection: "m-l-auto",
  },
  weightMgmt: {
    title: "EFFICIENT WEIGHT MANAGEMENT",
    body: "Weight management becomes easier when you harness the power of metabolism and induced thermogenesis. Experience the physiological difference through MCT’s inherent ability to increase bodily energy expenditure, aiding natural satiety, and improving metabolism.",
    image: skewWeightMgmtImg,
    imgDirection: "left",
    imgSpaceDirection: "m-r-auto",
  },
  cognitiveHealth: {
    title: "FOUNDATIONAL BENEFITS FOR COGNITIVE HEALTH",
    body: "Exploit the benefits of ketone metabolism for its natural neuroprotective properties for better brain health, ultimately aiding in improving working memory and overall cognitive function.",
    image: skewCogHealthImg,
    imgDirection: "right",
    imgSpaceDirection: "m-l-auto",
  },
  benefits: {
    title: "LEVERAGE UPON THE BENEFIT OF MCT",
    body: "How amazing that medium-chain triglycerides (MCTs) are fats that help you burn fat! We are often amazed how many people still do not know what MCT is, let alone what the benefits it does for our body and health. MCT ESSENTIALS is Monolith Nutrition’s flagship nutritional supplement that changes the way you approach healthy weight management.",
    image: skewCoconutImg,
    imgDirection: "right",
    imgSpaceDirection: "m-l-auto",
    button: {
      label: "HOW DOES MCT HELPS YOU?",
      link: "/about/why-monolith-nutrition",
    },
  },
};

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.homePage}>
        <Banner />
        <div className="container">
          <div className="productIntro">
            <div className={styles.productHero}>
              <img
                className="m-b-15"
                src={sachetImg}
                alt="Monolith Nutrition-MCT Essentials-Lemon Blast-Sachet"
              />
              <div className={styles.productHeroDesc}>
                <span className="text-red">Big</span> Things Come In{" "}
                <span className="text-red">Small</span> Packages
              </div>
            </div>

            <div className={styles.productIngredientRow}>
              {productIngredients.map((el, i) => (
                <ProductIngredientBox
                  product={el}
                  key={`product-ingredient-${i}`}
                />
              ))}
            </div>
          </div>
        </div>

        <div
          className="parallax"
          style={{ backgroundImage: `url("${basketballImg}")` }}
        ></div>

        <div className="quoteSection">
          <div className="container">
            <div className="big-quote text-center m-t-50 fw-300">
              It's not just about what you put into your body, but also what
              your body absorbs."
            </div>
          </div>
        </div>

        <div className={`skewFromRight bgBlack p-relative text-white skewRow`}>
          <div className="blackSkewBg">
            <div className="container">
              <ImgTxtTwoCol data={mctBenefits.gutHealth} />
            </div>
          </div>
        </div>

        <div className={`skewFromLeft bgBlack p-relative text-center skewRow`}>
          <div className="whiteSkewBg">
            <div className="container">
              <ImgTxtTwoCol data={mctBenefits.weightMgmt} />
            </div>
          </div>
        </div>

        <div className={`skewFromRight bgBlack p-relative text-white skewRow`}>
          <div className="blackSkewBg">
            <div className="container">
              <ImgTxtTwoCol data={mctBenefits.cognitiveHealth} />
            </div>
          </div>
        </div>

        <div className={`skewFromLeft bgBlack p-relative skewRow`}>
          <div className="whiteSkewBg">
            <div className="container">
              <ImgTxtTwoCol data={mctBenefits.benefits} />
            </div>
          </div>
        </div>

        <div className={`skewFromLeft bgRed p-relative text-white skewRow`}>
          <div className="primary-bg">
            <div className="container" style={{ minHeight: "200px" }}></div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <img
          className="full-width m-t-30"
          src={freeSampleImg}
          alt="free sample"
        />

        <div className="m-t-10">
          <div className="flex j-c-center a-i-center gap-15">
            <Link
              className="btn btn-primary btn-large"
              to={"/free-sample?pid=24916"}
            >
              Get your free sample now
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Home;
